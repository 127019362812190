import Default from 'gatsby-theme-carbon/src/templates/Default';
import React from 'react';
import { SetNewPasswordForm } from './password-reset'
export default function PasswordSetPage({pageContext, location}) {
  pageContext = {...pageContext, frontmatter: {title: "Reset Password"}};

  return (
    <Default pageContext={pageContext} location={location}>
      <SetNewPasswordForm />
    </Default>    
  )
}
